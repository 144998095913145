// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');

* {
  color: gray;
}

/*.centerBody {
    background-color: #b5e1de;
}*/

.headerBorder {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-bottom: 5px solid;
}
.placeholderZindex{
  z-index: 0 !important;
}
.footer {
  background-color: rgba(43, 61, 76, 0.7);
  border-top: none;
}

.pgb .step {
  text-align: center;
  position: relative;
}

.pgb h2 {
  font-size: 1.3rem;
}

.pgb .step p {
  position: absolute;
  height: 60px;
  width: 100%;
  text-align: center;
  display: block;
  z-index: 3;
  color: #fff;
  font-size: 160%;
  line-height: 55px;
  opacity: 0.7;
}

.pgb .active.step p {
  opacity: 1;
  font-weight: 600;
}

.pgb .img-circle {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #9e9e9e;
  border: 4px solid #fff;
}

.pgb .complete .img-circle {
  background-color: #4caf50;
}

.pgb .active .img-circle {
  background-color: #ff9800;
}

.pgb .step .img-circle:before {
  content: '';
  display: block;
  background: #9e9e9e;
  height: 4px;
  width: 50%;
  position: absolute;
  bottom: 50%;
  left: 0;
  z-index: -1;
  margin-right: 24px;
}

.pgb .step .img-circle:after {
  content: '';
  display: block;
  background: #9e9e9e;
  height: 4px;
  width: 50%;
  position: absolute;
  bottom: 50%;
  left: 50%;
  z-index: -1;
}

.pgb .step.active .img-circle:after {
  background: #9e9e9e;
}

.pgb .step.complete .img-circle:after,
.pgb .step.active .img-circle:before {
  background: #4caf50;
}

.pgb .step:last-of-type .img-circle:after,
.pgb .step:first-of-type .img-circle:before {
  display: none;
}

/* START LOGIN */
/*Main Login */
.main-login {
  width: 50%;
  margin: auto;
}
.div-form-login {
  width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.loginContainer {
  display: flex;
  flex-direction: row;
  height: 95vh;
  width: 100%;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.footer-login{
  height: 5vh;
}

.loginColum {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sideLogin {
  align-items: center;
  /* background-image: url("../assets/images/logo-vtv-p.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #000112;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2300062f'/%3E%3Cstop offset='1' stop-color='%2300062f' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23050131'/%3E%3Cstop offset='1' stop-color='%23050131' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%2305054e'/%3E%3Cstop offset='1' stop-color='%2305054e' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23000112'/%3E%3Cstop offset='1' stop-color='%23000112' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23000A4B'/%3E%3Cstop offset='1' stop-color='%23000A4B' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23090050'/%3E%3Cstop offset='1' stop-color='%23090050' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.img-login {
  width: 35rem;
  opacity: 0.9
}

.sideLogin::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.h4-login {
  font-family: 'Poppins', sans-serif;
  font-size: x-large;
  font-weight: bold;
  color: black;
  text-align: start;
  letter-spacing: 1px;
}
.h4-login + p {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
}
/*Input Login*/
.group {
  width: 100%;
  display: flex;
  line-height: 30px;
  align-items: center;
  position: relative;
}

.input-login {
  width: 100%;
  height: 55px;
  line-height: 30px;
  padding: 0 5rem;
  padding-left: 3rem;
  border: 2px solid transparent;
  border-radius: 10px;
  outline: none;
  background-color: #ebebec;
  color: #0d0c22;
  transition: 0.5s ease;
}

.input-login::placeholder {
  color: #2e2e2e;
  font-family: 'Poppins', sans-serif;
}

.input-login:focus,
.input-login:hover {
  outline: none;
  border-color: rgb(18, 28, 122);
  background-color: #fff;
  box-shadow: 0 0 0 5px rgba(29, 38, 112, 0.3);
}

.icon-login {
  position: absolute;
  left: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: 1.9px;
}
/*Button login */
.div-button-login {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.button-login {
  --primary-color: #121041;
  --secondary-color: #fff;
  --hover-color: #111;
  --arrow-width: 10px;
  --arrow-stroke: 2px;
  box-sizing: border-box;
  border: 0;
  border-radius: 12px;
  color: var(--secondary-color);
  padding: 0.8em 2.8em;
  background: var(--primary-color);
  display: flex;
  transition: 0.2s background;
  align-items: center;
  gap: 0.6em;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

.button-login .arrow-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-login .arrow {
  margin-top: 1px;
  width: var(--arrow-width);
  background: var(--primary-color);
  height: var(--arrow-stroke);
  position: relative;
  transition: 0.2s;
}

.button-login .arrow::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  border: solid var(--secondary-color);
  border-width: 0 var(--arrow-stroke) var(--arrow-stroke) 0;
  display: inline-block;
  top: -3px;
  right: 3px;
  transition: 0.2s;
  padding: 3px;
  transform: rotate(-45deg);
}

.button-login:hover {
  background-color: var(--hover-color);
}

.button-login:hover .arrow {
  background: var(--secondary-color);
}

.button-login:hover .arrow:before {
  right: 0;
}

@media (max-width: 1285px) {
  .div-form-login {
    width: 25rem;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 1024px) {
  .img-login {
    padding-bottom: 90%;
    width: 20rem;
  }
  .main-login {
    padding-bottom: 40%;

  }
}

@media (max-width: 800px) {
  .loginContainer {
    grid-template-columns: 55% 1fr;
  }

  .sideLogin .main-msg {
    font-size: 2.7rem;
  }
  .div-form-login {
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
  }
   .img-login {
    padding-bottom:0;
    width: 20rem;
  }
  .main-login {
    padding-bottom: 0;

  }



}

@media (max-width: 700px) {
  .loginContainer {
    grid-template-columns: 11fr;
    display: flex;
    flex-direction: column;
  }

  .main-login {
    height: 40rem;
    margin-top: 1em;
    margin-right: 9em;
    padding-bottom: 0;
  }
  .div-form-login {
    width: 20rem;
    margin-left: auto;
    margin-right: auto;
  }
  .img-login {
    margin: auto;
    margin-top: 6%;
    padding-bottom: 0;
  }
}
@media(max-width: 450px) {
   .div-form-login {
    width: 17rem;
    margin-left: auto;
    margin-right: auto;
  }
  .main-login {
    height: 37rem;
    margin-top: 1em;
    margin-right: 9rem;
  }
  .img-login {
    text-align: center;
    width: 20rem;
    margin-right: 3.1em;
    margin-top: 3.8em;

  }
  .main-login {
    height:40rem;
    margin-top: 1em;
    margin-right: 9rem;
  }
}
@media (max-width: 380px) {
  .div-form-login {
    width: 15rem;
    margin-left: auto;
    margin-right: auto;
  }
  .main-login {
    height: 37rem;
    margin-top: 1em;
    margin-right: 6em;
  }
  .img-login {
    width: 17rem;
  }
  .main-login {
    height: 40rem;
    margin-top: 1em;
    margin-right: 6.8em;
  }
  .nameDisplay {
    font-size: x-small;
  }
}

@media (max-width: 325px) {
  .img-login {
    width: 14rem;
   margin-top: 2em;
   margin-left: 2.9em;
  }
   .div-form-login {
    width: 14rem;
    font-size: small;
    margin-left: auto;
    margin-right: auto;
  }
  .input-login {
  width: 100%;
  font-size: small;
  height: 55px;
  line-height: 30px;
  padding: 0 5rem;
  padding-left: 3rem;
  border: 2px solid transparent;
  border-radius: 10px;
  outline: none;
  background-color: #ebebec;
  color: #0d0c22;
  transition: 0.5s ease;
}
}
@media (max-width: 265px) {
  .sideLogin{
    display: none;
  }

  .div-form-login {
    width: 14rem;
    margin-left: auto;
    margin-right: auto;
  }
}
/* END LOGIN */

//START LOADING
.rootLoading {
  text-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5051;
  background-color: rgba(0, 0, 0, 0.42);
  overflow: none;
}

.containerLoading {
  height: 100px;
  width: 100px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
}

.loaderLoading {
  border: 8px solid #f6f6f6;
  border-radius: 50%;
  border-top: 8px solid #343875;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

//ENDLOADING

.text-red {
  color: red;
}

.rbc-month-row {
  min-height: 50px;
}

.rbc-day-bg.selected {
  background-color: rgb(0, 139, 69);
}
// .rbc-day-bg.selected.morning {

//     background: linear-gradient(to bottom, blue 50%, red 50%);

// }
// .rbc-button-link .rbc-date-cell{
//   color: white;
// }

.rbc-day-bg.selected.color2 {
  background-color: rgba(56, 76, 165, 0.952);
  color: white;
  // background-color: rgba(56, 76, 165, 0.7);
}
.rbc-day-bg.selected.disabled {
  background-color: rgba(153, 156, 168, 0.952);
}
// .rbc-button-link:hover {
//   color: white !important;
// }

// .rbc-button-link {
//   color: black;
// }

.navBarHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 470px) {
  .nameDisplay {
    font-size: small;
  }
  .avatar-header{
    width: 1.6em;
  }
}

.rbc-event {
  .rbc-event-label {
    display: none;
  }

  .rbc-event-content {
    padding: 8px;
    color: white;
  }

  &.available {
    > .rbc-event-content {
      color: black !important;
    }

    background-color: white !important;
  }

  background-color: darkred !important;
}

.rbc-month-view {
  .rbc-event-content {
    padding: 0px;
  }
}

.rbc-time-view {
  .rbc-event-content {
    padding: 0px;
  }
}

/*START Generals */
.boxShadow {
  box-shadow: 0px 2px 10px 5px rgb(89 89 89 / 50%) !important;
}

.interFont {
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 900;
  font-size: 1.1rem;
  color: gray !important;
}

.borderRadius {
  border-radius: 15px;
}

.wrapper {
  background-color: #f1f1f1;
}

.titleText {
  font-size: 1.6rem !important;
}

/*END Generals */

/* START COLORS */
.sidebar-color {
  background: rgb(75, 80, 162);
  background: linear-gradient(180deg, rgba(49, 53, 129, 0.95) 0%, rgba(57, 59, 73, 0.98) 100%);
}

.footer-color {
  background: rgb(69, 80, 84);
  background: linear-gradient(130deg, rgba(57, 59, 73, 0.98) 0%, rgba(49, 53, 129, 0.95) 100%);
}

/*END COLORS */

/* START CALENDAR */
.rbc-event-content {
  color: black;
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
}

.rbc-event-label {
  color: black;
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 900;
  font-size: 1rem;
}

.rbc-event {
  padding: 0;
  display: flex;
  justify-content: center;
}

.centerTurnsHeaderContainer {
  display: flex;
}

.centerTurnsHeaderButton {
  display: flex;
  align-items: center;
}

.turnsHeaderSelect {
  height: 40px !important;
}

/* END CALENDAR */

/* START Graphics */

.commonContainer {
  padding: 6%;
  height: 100%;
  min-height: 550px;
}

.commonGraphicTitleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commonGraphicTitle {
  font-size: 1.5rem;
  font-weight: 600;
}

.graphicChartDescriptionContainer {
  display: flex;
  align-items: center;
  min-width: 85px;
}

.graphicChartDescription {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-left: 5%;
}

.graphicChartDescriptionContainer span {
  color: #202020;
}

.graphicChartTitleContainer {
  width: 100%;
}

.graphicChartIconsTextContainer {
  display: flex;
}

@media (max-width: 1200px) {
  .ageAndGenderSeparator {
    margin-top: 3rem;
  }
}

@media (max-width: 768px) {
  .commonGraphicTitle {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 550px) {
  .commonGraphicTitleContainer {
    flex-direction: column;
    text-align: center;
  }

  .graphicChartIconsTextContainer {
    flex-direction: row;
  }
}

@media (max-width: 410px) {
  .commonGraphicTitle {
    font-size: 1rem !important;
  }

  .graphicChartDescription {
    font-size: 0.7rem;
  }

  .apexcharts-text tspan {
    font-size: 0.7rem;
  }
}

/* END GRAPHICS */

/* START infomations cards */
.commonStadisticContainer {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  padding: 5%;
}

.titleInformationCard {
  border-bottom: 1px solid black;
}

.footerInformationCard {
  background-color: #9e9e9e;
}

.commonInformationCardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.valueInformationCard {
  font-size: 3.5rem;
}

.mousePointer {
  cursor: pointer;
}

//tamaño del sweet alert
.swal-wide {
  min-width: 950px;
}

/* END infomations cards */
